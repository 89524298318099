import React, { useEffect, useState, useRef } from 'react';
import '../css/homepage.css';
import '../css/body.css';
import Footer from '../responsivebar/Footer';
import ProductCard from './cards/product_card';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { API_ENDPOINTS } from '../connectapi';
import RandomProducts from './cards/RandomProducts';
import Showvideo from '../responsivebar/Showvideo';
import Slideselect from '../showcontentes/SlideSelect';

function HomePage() {
  const [isButtonSticky, setButtonSticky] = useState(false);
  const sliderRef = useRef(null);
  const isDragging = useRef(false);
  const startPosition = useRef(0);
  const currentTranslate = useRef(0);
  const prevTranslate = useRef(0);
  const animationRef = useRef(null);
  const [collection, setCollection] = useState({});
  const [products, setProducts] = useState([]);
  const isLinkClicked = useRef(false);
  const navigate = useNavigate(); // Using navigate for programmatic navigation

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector('.full-screen-section');
      const sectionTop = section.getBoundingClientRect().top;
      const sectionHeight = section.offsetHeight;
      const windowHeight = window.innerHeight;

      if (sectionTop < windowHeight - 100 && window.scrollY < sectionHeight - windowHeight) {
        setButtonSticky(true);
      } else {
        setButtonSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const fetchCollectionAndProducts = async () => {
      try {
        // Fetch the active collection (for the homepage)
        const collectionResponse = await axios.get(API_ENDPOINTS.getHomepageCollection);
        const collectionData = collectionResponse.data[0]; // PHP should return the collection in the correct format
        setCollection(collectionData);
        // Fetch products by the active collection's ID
        const productsResponse = await axios.get(`${API_ENDPOINTS.getProductsByCollection}?collectionId=${collectionData.procollection_id}`);
        setProducts(Object.values(productsResponse.data));
      } catch (error) {
        console.error('Error fetching collection and products:', error);
      }
    };

    fetchCollectionAndProducts();
  }, []);

  const setSliderPosition = () => {
    if (sliderRef.current) {
      sliderRef.current.style.transform = `translateX(${currentTranslate.current}px)`;
    }
  };
  const handleCollectionClick = (collectionId) => {
    navigate(`/listpageallItems?collection_id=${collectionId}`); // Navigate to the list page
  };


  const handleTouchStart = (e) => {
    isDragging.current = true;
    isLinkClicked.current = false; // Reset the link click flag
    startPosition.current = e.touches[0].clientX;
    animationRef.current = requestAnimationFrame(animation);
    if (sliderRef.current) {
      sliderRef.current.style.transition = 'transform 0s';
    }
  };

  const handleMouseDown = (e) => {
    isDragging.current = true;
    isLinkClicked.current = false; // Reset the link click flag
    startPosition.current = e.pageX;
    animationRef.current = requestAnimationFrame(animation);
    if (sliderRef.current) {
      sliderRef.current.style.transition = 'transform 0s';
    }
  };

  const handleTouchEnd = () => {
    handleEnd();
  };

  const handleMouseUp = () => {
    handleEnd();
  };

  const handleTouchMove = (e) => {
    if (isDragging.current) {
      const currentPosition = e.touches[0].clientX;
      currentTranslate.current = prevTranslate.current + currentPosition - startPosition.current;
      isLinkClicked.current = true; // Dragging detected, prevent link click
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging.current) {
      const currentPosition = e.pageX;
      currentTranslate.current = prevTranslate.current + currentPosition - startPosition.current;
      isLinkClicked.current = true; // Dragging detected, prevent link click
    }
  };

  const handleEnd = () => {
    isDragging.current = false;
    cancelAnimationFrame(animationRef.current);
    if (sliderRef.current) {
      sliderRef.current.style.transition = 'transform 0.5s ease-out';
      currentTranslate.current = Math.max(
        Math.min(currentTranslate.current, 0),
        -sliderRef.current.scrollWidth + sliderRef.current.clientWidth
      );
      setSliderPosition();
      prevTranslate.current = currentTranslate.current;
    }
  };

  const animation = () => {
    setSliderPosition();
    if (isDragging.current) {
      requestAnimationFrame(animation);
    }
  };

  const handleLinkClick = (e) => {
    if (isLinkClicked.current) {
      e.preventDefault(); // Prevent the link click if dragging occurred
    }
  };

  useEffect(() => {
    const section = document.querySelector('.split-section');

    const handleScroll = () => {
      const sectionTop = section.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (sectionTop < windowHeight - 100) {
        section.classList.add('visible');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Trigger once in case it's already in view
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!collection) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <div className="home-page">
        <section className="full-screen-section">
          <img src={`${API_ENDPOINTS.getAPI}${collection.procollection_img}`} alt={collection.procollection_name} className="background-image" onClick={() => handleCollectionClick(collection.procollection_id)} />
          {/*
          <div>
            <button className={`see-collection-btn ${isButtonSticky ? 'sticky' : ''}`} onClick={() => handleCollectionClick(collection.procollection_id)}>
            {collection.procollection_name}
            </button>
          </div>*/}
        </section>
        <section draggable="false">
          <div className="section2-container">
            <div className="slider-container">
              <div
                className="slider"
                ref={sliderRef}
                onMouseDown={handleMouseDown}
                onMouseLeave={handleMouseUp}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onTouchMove={handleTouchMove}
              >
                <div className="slider-content" draggable="false">
                  {products.map((product) => (
                    <div key={product.pro_id}>
                      <Link
                        draggable="false"
                        to={`/productDetail/${product.pro_id}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                        onClick={handleLinkClick} // Attach the click handler to prevent clicks
                      >
                        <ProductCard
                          images={product.variations.flatMap((v) => v.images)} // Make sure this is the correct path for images
                          name={product.name}
                          price={product.variations[0]?.price || 0}
                          colors={[...new Set(product.variations.map((v) => v.color))]} // Ensure variations have a 'color' field
                        />
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
         <Slideselect />
        </section>
        <section className="section-news">
          <Showvideo />
        </section>
        <Link to={'/productDetail/11dbaa10'} style={{color: 'inherit', textDecoration: 'inherit'}}>
          <section className="split-section">

            <div className="split-left">
              <div className="tilted-image-container">
                <img
                  src="https://testsever.gorgeouswomanth.com/gorgeousapi/uploads/variation_images/6732cac24d257-resize-image-13.jpg"
                  alt="Model Wearing Shirt"
                  className="tilted-image"
                />
              </div>
            </div>
            <div className="split-right">
              <h2>สินค้าขายดี</h2>
              <p>
                สัมผัสความสะดวกสบายและสไตล์อย่างที่ไม่เคยมีมาก่อนด้วยคอลเลกชันเสื้อเชิ้ตระดับพรีเมียมล่าสุดของเรา</p>
            </div>

          </section>
        </Link>
        <section>
          <RandomProducts />
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
