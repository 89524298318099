import React, { useState, useEffect, useRef } from 'react';
import { API_ENDPOINTS } from '../../connectapi';
import axios from 'axios';
import './addproductform.css';
import {
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  convertToRaw,
  ContentState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const CreateProductForm = ({
  onProductCreated,
  onProductUpdated,
  productId = null,
  isEdit = false,
}) => {
  const [types, setTypes] = useState([]);
  const [collections, setCollections] = useState([]);
  const [styles, setStyles] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [formData, setFormData] = useState({
    pro_name: '',
    pro_detail: '',
    prosty_id: '',
    procollection_id: '',
  });
  const [videoFile, setVideoFile] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [addingNewStyle, setAddingNewStyle] = useState(false);
  const [newStyleName, setNewStyleName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const editorRef = useRef(null);

  // Handle key commands (e.g., Ctrl+B for bold)
  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      handleEditorChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  // Custom key bindings (optional)
  const keyBindingFunction = (e) => {
    // For example, map Ctrl+S to save action
    if (e.keyCode === 83 /* `S` key */ && e.ctrlKey) {
      e.preventDefault();
      // Implement save action
      return 'save';
    }
    return getDefaultKeyBinding(e);
  };

  // Apply inline styles from toolbar buttons
  const applyInlineStyle = (style) => {
    handleEditorChange(RichUtils.toggleInlineStyle(editorState, style));
  };

  // Fetch product types, collections, and product data on mount
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [typesResponse, collectionsResponse] = await Promise.all([
          axios.get(API_ENDPOINTS.getProductTypes, { withCredentials: true }),
          axios.get(API_ENDPOINTS.getProductCollections, { withCredentials: true }),
        ]);
        setTypes(typesResponse.data);
        setCollections(collectionsResponse.data);

        if (isEdit && productId) {
          // Fetch product data
          const response = await axios.get(
            API_ENDPOINTS.getProductById(productId),
            { withCredentials: true }
          );
          if (response.status === 200 && response.data) {
            // Populate formData with existing product data
            const product = response.data;
            setFormData({
              pro_name: product.pro_name,
              pro_detail: product.pro_detail,
              prosty_id: product.prosty_id,
              procollection_id: product.procollection_id,
            });
            // Find the type object
            const productType = typesResponse.data.find(
              (type) => type.proty_id == product.proty_id
            );
            setSelectedType(productType);

            // Load editor state from product.pro_detail
            const contentBlock = htmlToDraft(product.pro_detail);
            if (contentBlock) {
              const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks,
                contentBlock.entityMap
              );
              setEditorState(EditorState.createWithContent(contentState));
            }
          } else {
            alert('Failed to load product data.');
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [isEdit, productId]);

  // Fetch styles when selectedType changes
  useEffect(() => {
    if (selectedType) {
      fetchStyles(selectedType.proty_id);
    } else {
      setStyles([]);
    }
  }, [selectedType]);

  const typeIcons = {
    1: '👕', // Top
    2: '👖', // Bottom
    3: '🧢', // Hat
    4: '🎒', // Accessories
    5: '👟', // Shoes
    // Add more mappings as needed
  };

  // Fetch styles based on type ID
  const fetchStyles = async (typeId) => {
    try {
      const response = await axios.get(API_ENDPOINTS.getProductStyles(typeId), {
        withCredentials: true,
      });
      setStyles(response.data);
    } catch (error) {
      console.error('Error fetching styles:', error);
    }
  };

  // Handle form input changes
  const handleChange = (e, fieldName = null) => {
    if (fieldName) {
      // For direct value inputs
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: e,
      }));
    } else {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Handle video file selection
  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInMB = 10;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (file && file.size > maxSizeInBytes) {
      alert(`The file is too large. Please select a video smaller than ${maxSizeInMB}MB.`);
      setVideoFile(null);
      setVideoPreview(null);
      return;
    }

    if (file) {
      setVideoFile(file);
      setVideoPreview(URL.createObjectURL(file));
    }
  };

  // Handle adding a new style
  const handleNewStyleSubmit = async () => {
    if (newStyleName.trim()) {
      try {
        const data = new URLSearchParams();
        data.append('action', 'add_style');
        data.append('prosty_name', newStyleName);
        data.append('proty_id', selectedType.proty_id);

        const response = await axios.post(API_ENDPOINTS.addStyle, data, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          withCredentials: true,
        });

        if (response.status === 200 && response.data.prosty_id) {
          setStyles((prevStyles) => [...prevStyles, response.data]);
          setFormData((prevData) => ({ ...prevData, prosty_id: response.data.prosty_id }));
          setAddingNewStyle(false);
          setNewStyleName('');
        } else {
          alert('Error adding new style');
        }
      } catch (error) {
        console.error('Error adding new style:', error);
        if (error.response && error.response.data) {
          console.error('Error response data:', error.response.data);
        }
        alert('Error adding new style');
      }
    }
  };

  const handleNewStyleCancel = () => {
    setAddingNewStyle(false);
    setNewStyleName('');
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    const contentState = state.getCurrentContent();
    const htmlContent = draftToHtml(convertToRaw(contentState));

    setFormData((prevData) => ({
      ...prevData,
      pro_detail: htmlContent,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Validate required fields
    if (
      !formData.pro_name ||
      !formData.pro_detail ||
      !formData.prosty_id ||
      !formData.procollection_id ||
      !selectedType?.proty_id
    ) {
      alert('Please fill in all required fields.');
      setIsLoading(false);
      return;
    }

    const uploadData = new FormData();
    uploadData.append('pro_name', formData.pro_name);
    uploadData.append('pro_detail', formData.pro_detail);
    uploadData.append('proty_id', selectedType.proty_id);
    uploadData.append('prosty_id', formData.prosty_id);
    uploadData.append('procollection_id', formData.procollection_id);
    uploadData.append('action', isEdit ? 'update_product' : 'create_product');
    if (videoFile) uploadData.append('pro_videourl', videoFile);
    if (isEdit && productId) uploadData.append('pro_ID', productId);

    try {
      const endpoint = isEdit ? API_ENDPOINTS.updateProduct : API_ENDPOINTS.createProduct;
      const response = await axios.post(endpoint, uploadData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });

      console.log('API Response:', response.data); // Debug log

      if (
        response.status === 200 &&
        response.data.message === 'Product created successfully'
      ) {
        alert('Product created successfully!');
        // Reset form
        setFormData({
          pro_name: '',
          pro_detail: '',
          prosty_id: '',
          procollection_id: '',
        });
        setSelectedType(null);
        setVideoFile(null);
        setVideoPreview(null);
        setEditorState(EditorState.createEmpty());
        onProductCreated(response.data.pro_ID);
      } else {
        alert(`Error creating product: ${response.data.message || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error submitting product:', error);
      if (error.response) {
        console.error('Error Response:', error.response.data);
        alert(`Error: ${error.response.data.message || 'Server error'}`);
      } else if (error.request) {
        alert('No response from server. Please try again later.');
      } else {
        alert(`Error: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="create-product-form">
      {isLoading && (
        <div className="loading-spinner">
          {/* This can be a simple CSS spinner or a loading GIF */}
          <div className="spinner"></div>
        </div>
      )}
      {(!selectedType && !isEdit) ? (
        <>
          <p className="advice-text">Please select a product category to begin:</p>
          <div className="category-buttons">
            {types && types.length > 0 ? (
              types.map((type) => (
                <button
                  key={type.proty_id}
                  onClick={() => setSelectedType(type)}
                  className="category-button"
                >
                  <span className="category-icon">
                    {typeIcons[String(type.proty_id)] || '❓'}
                  </span>
                  {type.proty_name}
                </button>
              ))
            ) : (
              <p>No product types available.</p>
            )}
          </div>
        </>
      ) : (
        <div className="product-form">
          <div className="product-form-header">
            <h2>{isEdit ? 'แก้ไขสินค้า' : `เพิ่มสินค้าประเภท ${selectedType.proty_name}`}</h2>
            {!isEdit && (
              <button onClick={() => setSelectedType(null)} className="back-button">
                เลือกใหม่
              </button>
            )}
          </div>

          <form onSubmit={handleSubmit}>
            <label>
              เลือกคอลเล็กชั่น:
              <select
                name="procollection_id"
                value={formData.procollection_id}
                onChange={handleChange}
                required
              >
                <option value="">Select Collection</option>
                {collections.map((collection) => (
                  <option
                    key={collection.procollection_id}
                    value={collection.procollection_id}
                  >
                    {collection.procollection_name}
                  </option>
                ))}
              </select>
            </label>

            <label>
              สไตล์สินค้า:
              {!addingNewStyle ? (
                <select
                  name="prosty_id"
                  value={formData.prosty_id}
                  onChange={(e) => {
                    if (e.target.value === 'add_new') {
                      setAddingNewStyle(true);
                    } else {
                      handleChange(e);
                    }
                  }}
                  required
                >
                  <option value="">Select Style</option>
                  {styles.map((style) => (
                    <option key={style.prosty_id} value={style.prosty_id}>
                      {style.prosty_name}
                    </option>
                  ))}
                  <option value="add_new">+ Add new Style</option>
                </select>
              ) : (
                <div className="new-style-input">
                  <input
                    type="text"
                    value={newStyleName}
                    onChange={(e) => setNewStyleName(e.target.value)}
                    placeholder="Enter new style"
                    required
                  />
                  <button type="button" onClick={handleNewStyleSubmit}>
                    ✔️
                  </button>
                  <button type="button" onClick={handleNewStyleCancel}>
                    ❌
                  </button>
                </div>
              )}
            </label>

            <label>
              ชื่อสินค้า:
              <input
                type="text"
                name="pro_name"
                value={formData.pro_name}
                onChange={handleChange}
                required
              />
            </label>
            <div className="editor-toolbar">
              <button
                type="button"
                onMouseDown={(e) => {
                  e.preventDefault();
                  applyInlineStyle('BOLD');
                }}
              >
                Bold
              </button>
              <button
                type="button"
                onMouseDown={(e) => {
                  e.preventDefault();
                  applyInlineStyle('ITALIC');
                }}
              >
                Italic
              </button>
              <button
                type="button"
                onMouseDown={(e) => {
                  e.preventDefault();
                  applyInlineStyle('UNDERLINE');
                }}
              >
                Underline
              </button>
            </div>
            <label>
              รายละเอียดสินค้า:
              <div className="editor-container" onClick={() => editorRef.current.focus()}>
                <Editor
                  editorState={editorState}
                  onChange={handleEditorChange}
                  handleKeyCommand={handleKeyCommand}
                  keyBindingFn={keyBindingFunction}
                  ref={editorRef}
                />
              </div>
            </label>

            <label>
              อัพโหลดวิดีโอ:
              <input type="file" accept="video/*" onChange={handleVideoChange} />
              {videoPreview && (
                <video width="400" controls>
                  <source src={videoPreview} type="video/mp4" />
                  Your browser does not support HTML5 video.
                </video>
              )}
            </label>

            <center>
              <button type="submit">{isEdit ? 'อัปเดตสินค้า' : 'เพิ่มสินค้า'}</button>
            </center>
          </form>
        </div>
      )}
    </div>
  );
};

export default CreateProductForm;
