const API_BASE_URL = 'https://testsever.gorgeouswomanth.com/gorgeousapi/'; // Ensure this is correct

export const API_ENDPOINTS = {
  getAPI: `${API_BASE_URL}`,
  getProducts: `${API_BASE_URL}getProducts.php`,
  getlistProducts: `${API_BASE_URL}getlistProducts.php`,
  getAllStyles: `${API_BASE_URL}getAllStyles.php`,
  getColors: `${API_BASE_URL}getColors.php`,
  getAllSizes: `${API_BASE_URL}getAllSizes.php`,
  getCollections: `${API_BASE_URL}getCollections.php`,
  getHomepageCollection: `${API_BASE_URL}getShowProducts.php`,
  getProductsByCollection: `${API_BASE_URL}getProductsByCollection.php`,
  getCollectionById: getCollectionById => `${API_BASE_URL}getCollectionById.php?collectionId=${getCollectionById}`,
  getStylesByCategory: getStylesByCategory => `${API_BASE_URL}getStylesByCategory.php?proty_id=${getStylesByCategory}`,
  getdetailProducts: getdetailProducts => `${API_BASE_URL}getProductDetail.php?productId=${getdetailProducts}`,
  getlistsuggestions: getlistsuggestions => `${API_BASE_URL}getProductSuggestions.php?productId=${getlistsuggestions}`,
  addStyle: `${API_BASE_URL}addStyle.php`,
  addCollection: `${API_BASE_URL}addCollection.php?action=addCollection`,
  setActiveCollection: collectionId => `${API_BASE_URL}setActiveCollection.php?collectionId=${collectionId}`,
  getproductsvar: `${API_BASE_URL}/product_api.php?action=get_productsvar`,
  addProduct: `${API_BASE_URL}addProduct.php`,

  createProduct: `${API_BASE_URL}product_api.php`,
  getProductById: (id) => `${API_BASE_URL}product_api.php?id=${id}`,
  updateProduct: `${API_BASE_URL}product_api.php`,
  deleteProduct: (id) => `${API_BASE_URL}product_api.php?id=${id}`,
  getProductTypes: `${API_BASE_URL}product_api.php?action=get_types`,
  getProductStyles: (type_id) => `${API_BASE_URL}product_api.php?action=get_styles&type_id=${type_id}`,
  getProductCollections: `${API_BASE_URL}product_api.php?action=get_collections`,

  addSize: `${API_BASE_URL}variation_api.php?action=add_size`,
  getSizesByType: (proty_id) => `${API_BASE_URL}variation_api.php?action=get_sizebyid&type_id=${proty_id}`,
  getCategories: `${API_BASE_URL}variation_api.php?action=get_categories`,
  addCategory: `${API_BASE_URL}variation_api.php?action=add_category`,
  createProductVariation: `${API_BASE_URL}variation_api.php`,
  uploadImage: `${API_BASE_URL}variation_api.php?action=upload_image`,
  getColors: `${API_BASE_URL}variation_api.php?action=get_colors`,
  addColor: `${API_BASE_URL}variation_api.php?action=add_color`,

  getDetailSize: (size_id) => `${API_BASE_URL}getDetailSize.php?pro_ID=${size_id}`,
};