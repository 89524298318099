// SlideSelect.js
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./SlideSelect.css";

const SlideSelect = () => {
    const navigate = useNavigate(); // Using navigate for programmatic navigation

    const handleTypeClick = (typeId) => {
    
        // Construct the new URL with the collection_id (if it exists)
        let newUrl = `/listpageallItems?type_id=${typeId}`;
    
        navigate(newUrl); // Navigate to the new URL
      };
      
    const categories = [
        { name: "Shirts", image: "images/shirtstyle-selection.jpg", link: 1 },
        { name: "Hats", image: "images/hatstyle-selection.jpg", link: 3 },
        { name: "Bags", image: "images/begstyle-selection.jpg", link: 4 },
        // Add more categories as needed
    ];

    return (
        <div className="slideselect">
            <div className="header">
                <h2 className="header-title"></h2>
                <Link to="/listpageallItems">
                    <button className="header-button">ดูทั้งหมด</button>
                </Link>
            </div>
            <div className="selectcard-container">

                {categories.map((category, index) => (
                    <div onClick={() => handleTypeClick(category.link)} key={index} className="selectcard">
                        <img src={category.image} alt={category.name} className="selectcard-image" />
                        <div className="selectcard-text">
                            <h3>{category.name}</h3>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SlideSelect;